import { DocumentNode } from 'graphql';

import { INCIDENT_STATUSES } from '../../constants/SQConstants';
import {
    getAllIncidentsQuery,
    getIncidentsByStatusQuery,
} from './GraphQLQueries';

class QueryHelper {
    /**
     * Get a graphql query by tab and orgid
     * @param tabId
     * @param orgId
     * @returns DocumentNode
     */
    static getQuery(
        tabId: string,
        orgId: number,
        pageIndex: number,
        pageSize: number,
        sortField: string,
        sortAscending: boolean,
        personId: string
    ): DocumentNode {
        let query: DocumentNode;
        let status: string;
        let orgIdStr = orgId.toString();

        switch (tabId) {
            case INCIDENT_STATUSES.NEEDS_ATTENTION.id:
                status = INCIDENT_STATUSES.NEEDS_ATTENTION.value;
                query = getIncidentsByStatusQuery(
                    orgIdStr,
                    status,
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
                break;
            case INCIDENT_STATUSES.VIEWED.id:
                status = INCIDENT_STATUSES.VIEWED.value;
                query = getIncidentsByStatusQuery(
                    orgIdStr,
                    status,
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
                break;
            case INCIDENT_STATUSES.COMPLETED.id:
                status = INCIDENT_STATUSES.COMPLETED.value;
                query = getIncidentsByStatusQuery(
                    orgIdStr,
                    status,
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
                break;
            case INCIDENT_STATUSES.NEW.id:
                query = getIncidentsByStatusQuery(
                    orgIdStr,
                    'New',
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
                break;
            case INCIDENT_STATUSES.ARCHIVED.id:
                query = getIncidentsByStatusQuery(
                    orgIdStr,
                    'Archived',
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
                break;
            default:
                query = getAllIncidentsQuery(
                    orgIdStr,
                    pageIndex,
                    pageSize,
                    sortField,
                    sortAscending,
                    personId
                );
        }

        return query;
    }
}

export { QueryHelper };

export class PAGE_URLS {
    public static readonly INCIDENTS = 'incidents';
}

export class WEBFORMS_URLS {
    public static readonly INCIDENTS = 'incidents-list';
    public static readonly ACTIONS = 'actions-list';
    public static readonly OBSERVATIONS = 'observations-list';
    public static readonly MAIN = 'main';
}

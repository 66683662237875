import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    NormalizedCacheObject,
} from '@apollo/client';

import getRealmApp from '../RealmApp';
import AuthenticationService from '../../services/AuthenticationService';

async function getValidAccessToken() {
    let app = getRealmApp();

    if (app.currentUser) {
        // To ensure that the accessToken being used for the Authorization header is not stale, we refresh
        // the user's custom data which refreshes the accessToken as well
        await app.currentUser.refreshCustomData();
        return app.currentUser.accessToken;
    } else {
        AuthenticationService.clearCookies();
        window.location.replace(process.env.REACT_APP_FORMS_URL!);
    }
}

const createGQLClient = () => {
    const clientQL: ApolloClient<NormalizedCacheObject> = new ApolloClient({
        link: new HttpLink({
            uri: process.env.REACT_APP_GRAPHQL_URI,
            fetch: async (uri: RequestInfo, options: RequestInit) => {
                const accessToken = await getValidAccessToken();
                options.headers = {} as { [key: string]: string };
                options.headers.Authorization = `Bearer ${accessToken}`;
                return fetch(uri, options);
            },
        }),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

    return clientQL;
};

export default createGQLClient;

/**
 * represents an absolutely positioned button at bottom of viewport
 */
import { FunctionComponent } from 'react';

export const Footer: FunctionComponent = ({ children }) => {
    return (
        <div className="footer">
            {children}
        </div>
    );
}

import * as Realm from 'realm-web';

import { UserCustomDatum } from '../types/schemas/schema';

let app: Realm.App<
    globalThis.Realm.DefaultFunctionsFactory &
        globalThis.Realm.BaseFunctionsFactory,
    UserCustomDatum
>;

// Returns the shared instance of the Realm app.
export default function getRealmApp(): Realm.App<
    globalThis.Realm.DefaultFunctionsFactory &
        globalThis.Realm.BaseFunctionsFactory,
    UserCustomDatum
> {
    if (app === undefined) {
        const appId = process.env.REACT_APP_REALM_APP_ID!; // Set Realm app ID here.
        const appConfig = {
            id: appId,
            timeout: 10000,
            app: {
                name: 'default',
                version: '0',
            },
        };
        app = new Realm.App<
            globalThis.Realm.DefaultFunctionsFactory &
                globalThis.Realm.BaseFunctionsFactory,
            UserCustomDatum
        >(appConfig);
    }

    return app;
}

/*** FNS TO DO WITH DATES ***/

const _DAYS_LONG = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
const _MONTHS_LONG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const _MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

/**
 * Check if a date is 'new'
 * @param date to check
 * @returns boolean true if date is within the past NEW_THRESHOLD_DAYS days, false otherwise
 */
const NEW_THRESHOLD_DAYS = 7;
const isDateNew = (date: Date): boolean => {
    let clone = new Date(date.valueOf());
    let datePlusWeek = clone.setDate(clone.getDate() + NEW_THRESHOLD_DAYS);

    return datePlusWeek >= new Date().getTime();
};

/**
 * get a date string for putting into a graphql query
 * this rounds to the nearest x minutes so that useQuery doesn't cause an infinite loop because of the variable changing
 * more info here: https://github.com/apollographql/react-apollo/issues/3644
 * @returns date json
 */
const getIsNewGraphQLDateVariable = (roundToXMinutes: number = 5): string => {
    const coeff = 1000 * 60 * roundToXMinutes;
    const date = new Date();

    let rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    rounded.setDate(rounded.getDate() - NEW_THRESHOLD_DAYS);

    const dateJSON = rounded.toJSON();

    return dateJSON;
};

/**
 * take a date object and format like 4:12 PM
 * @param date
 * @returns string
 */
const getShortTimeString = (date: Date): string => {
    date = new Date(date);
    var hours = date.getHours();
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const minutes = date.getMinutes();

    return `${hours % 12 || 12}:${
        minutes >= 10 ? '' : '0'
    }${minutes} ${suffix}`;
};

/**
 * take a date object and format like Friday, January 21, 2020
 * @param date
 * @returns string
 */
const getDateString = (date: Date): string => {
    return `${_DAYS_LONG[date.getDay()]}, ${
        _MONTHS_LONG[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
};

/**
 * take a date object and format like 19 Feb, 2021
 * @param date
 * @returns string
 */
const getDateStringShortMonth = (date: Date): string => {
    date = new Date(date);
    return `${date.getDate()} ${
        _MONTHS_SHORT[date.getMonth()]
    }, ${date.getFullYear()}`;
};

export {
    getDateString,
    getIsNewGraphQLDateVariable,
    getDateStringShortMonth,
    getShortTimeString,
    isDateNew,
};

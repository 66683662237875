import { useEffect, useRef } from 'react';

/**
 * Hook that exposes if component just mounted
 * @returns
 */
const useJustMounted = (): [boolean] => {
    const justMounted = useRef<boolean>(true);

    useEffect(() => {
        justMounted.current = false;
    }, []);

    return [justMounted.current];
};

export default useJustMounted;

/**
 * represents a row of filter tabs
 */
import { FunctionComponent } from 'react';

import { Tab } from '../../../types/Tab'
import { FilterTab } from '../FilterTab/FilterTab';
import "../../../content/css/FilterTabs.css";

type FilterTabProps = {
    tabs: Tab[],
    onClick(id:string): void,
    selectedTabId: string
};

export const FilterTabs: FunctionComponent<FilterTabProps> = ({ tabs, selectedTabId, onClick }) => {
    return (
        <div className="filtertabs_container">
            {tabs.map(tab => {
                let selected = selectedTabId === tab.id;
                return (
                    <FilterTab tab={tab} selected={selected} onClick={onClick} key={"filterTab" + tab.id}/>
                );
            })}
        </div>
    );
}
import React from 'react';
import useIsMounted from './useIsMounted';
import useJustMounted from './useJustMounted';

/**
 * Wrapper hook to expose booleans about component lifecycle
 * @returns tuple [
 *  justMounted: boolean
 *  isMounted: boolean
 *  checkMountStateBeforeSetState: <T>(dispatch<T>, arg: T) => void;
 *      checks isMounted = true before setting
 * ]
 */
const useLifeCycle = (): [
    boolean,
    boolean,
    <T>(dispatch: React.Dispatch<React.SetStateAction<T>>, arg: T) => void
] => {
    const [isMounted] = useIsMounted();
    const [justMounted] = useJustMounted();

    /**
     * helper to check if we are mounted beforew setting state
     * @param dispatch
     */
    function checkMountStateBeforeSetState<T>(
        dispatch: React.Dispatch<React.SetStateAction<T>>,
        arg: T
    ) {
        if (isMounted) dispatch(arg);
    }

    return [justMounted, isMounted, checkMountStateBeforeSetState];
};

export default useLifeCycle;

import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthProvider } from './providers/AuthProvider';
import createGQLClient from './helpers/graphql/GraphQLClient';
import { App } from './App';
import { HashRouter } from 'react-router-dom';

const clientQL = createGQLClient();

ReactDOM.render(
    <AuthProvider>
        <ApolloProvider client={clientQL}>
            <HashRouter>
                <App />
            </HashRouter>
        </ApolloProvider>
    </AuthProvider>,
    document.getElementById('root')
);

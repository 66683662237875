import React, { FunctionComponent } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

import Colors from '../../../constants/Colors';
import '../../../content/css/App.css';
import { LoadingSpinnerProps } from '../../../types/loading/LoadingProps';

/**
 * Loading spinner component
 */
export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
    message,
    block,
    color = Colors.darkGreen,
    secondaryColor = Colors.lightGrey,
    messageColor = Colors.black,
}) => {
    return (
        <div className="center_page_parent">
            <div className="center_page">
                <SpinnerCircularFixed
                    enabled={block}
                    size="100"
                    secondaryColor={secondaryColor}
                    color={color}
                />
                {message && (
                    <div style={{ color: messageColor }}>{message}</div>
                )}
            </div>
        </div>
    );
};

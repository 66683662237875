/**
 * Types to do with notifications
 */
import {
    ToastContainerProps,
    ToastHeaderProps,
    ToastProps,
} from 'react-bootstrap';

// can add as we go
export enum NotificationTheme {
    error = 'error',
    default = 'default',
}

export type Notification = {
    id: string; // a guid string
    headerProps?: ToastHeaderProps;
    headerText?: string;
    toastProps?: ToastProps;
    notificationText: string;
    theme?: NotificationTheme;
};

export type NotificationListProps = ToastContainerProps & {
    notifications: Notification[];
};

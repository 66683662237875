import { IIncidentService } from './interfaces/IIncidentService';
import { Incident, IncidentJSON } from '../types/Incident';

/**
 * IIncidentService implementation
 */
class IncidentService implements IIncidentService {
    /**
     * Transform incidents retrieved from mongo to an array of incident objects to be used in the app
     * @param orgIncidents
     * @param orgId
     * @returns
     */
    transformIncidents(orgIncidents: IncidentJSON[]): Incident[] {
        let results: Incident[] = [];
        let incidentRow: Incident;

        if (orgIncidents && orgIncidents.length > 0) {
            for (let i = 0; i < orgIncidents.length; i++) {
                incidentRow = this.setIncident(orgIncidents[i]);

                results.push(incidentRow);
            }
        }

        return results;
    }

    /**
     * Set incident object from the incidentJSON fetched from Mongo to be used in the app
     * @param incident
     * @param orgId
     * @returns
     */
    setIncident(incident: IncidentJSON): Incident {
        let incidentRow: Incident = {
            Id: incident._id,
            IncidentId: incident.customerIncidentId,
            IncidentStatus: incident.status?.name,
            IncidentDateTime: incident.occurenceDateTime,
            IncidentReportedDateTime: incident.createDateTimeStamp,
            IncidentImages:
                incident.medias && incident.medias.length > 0
                    ? [...incident.medias]
                    : [],
            IncidentLocationAndPersonInvolved: {
                IncidentLocation: incident.location,
                PersonInvolved: incident.personInvolved,
                ReportedBy: incident.reportedBy,
                IncidentProject: incident.project,
            },
            IncidentGeneralInfo: {
                IncidentDescription: incident.description,
                IncidentClassifications: incident.classifications,
                IncidentType: incident.type,
                IncidentTitle: incident.name || '',
                CustomerIncidentId: incident._id,
            },
            SQLServerId: incident.SQLServerId || '',
        };

        return incidentRow;
    }
}

export default new IncidentService();

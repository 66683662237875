const Colors = {
    darkGreen: '#006064',
    bgLightRed: '#FFE0E0',
    textRed: '#B40000',
    bgLightBlue: '#DEF8FF',
    textBlue: '#27A8CD',
    bgLightYellow: '#FFFFEE',
    white: '#FFFFFF',
    lightGrey: '#D3D3D3',
    black: '#333333',
};

export default Colors;

import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useAuth } from '../../../providers/AuthProvider';

type SessionExpiredProps = {
    show: boolean;
};

export const SessionExpiredModal: FunctionComponent<SessionExpiredProps> = ({
    show,
}) => {
    const { signOut } = useAuth();
    /**
     * Modal click event handler
     */
    const onHideModal = () => {
        signOut();
    };

    /**
     * Render session expired modal
     * @returns
     */
    const renderModal = () => {
        return (
            <Modal show={show} onHide={onHideModal}>
                <Modal.Header closeButton onClick={onHideModal}>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your session has expired. Please login again if you wish to
                    continue.
                </Modal.Body>
                <Modal.Footer>
                    <Button id="session_expiry_button" onClick={onHideModal}>
                        To login screen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return <React.Fragment>{renderModal()}</React.Fragment>;
};

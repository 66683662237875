import React, { useState } from 'react';

import Cookies from 'universal-cookie';
import { useAuth } from '../../providers/AuthProvider';
import { COOKIE_KEYS, SECRET_NAMES } from '../../constants/SQConstants';
import AuthenticationService from '../../services/AuthenticationService';

const useSession = (): [boolean, () => Promise<boolean>] => {
    const [restoringSession, setRestoringSession] = useState<boolean>(false);
    const { token, realmSignIn, secrets } = useAuth();

    /**
     * This function attempts to re-authenticate a user into supervisor queue
     * by checking any stored access token for validity and refreshing as needed
     * @returns boolean if successful, false otherwise
     */
    const restoreSession = async (): Promise<boolean> => {
        let isRestored: boolean = false;
        try {
            if (!secrets) return isRestored;

            const cookies = new Cookies();

            // try get a token to check
            let tokenToCheck =
                token || cookies.get(COOKIE_KEYS.API_ACCESS_TOKEN);

            // if there is no token to check boot to login
            if (!tokenToCheck) return isRestored;

            // is the token valid?
            const introspectedToken = await AuthenticationService.introspect(
                tokenToCheck,
                `Basic ${btoa(
                    `${process.env.REACT_APP_RESOURCE_NAME}:${
                        secrets[SECRET_NAMES.RESOURCE]
                    }`
                )}`
            );

            if (!introspectedToken) return false;

            const tryRefresh = introspectedToken?.active === false;
            // try refresh tokens and set tokenToCheck to the new access token
            // if we did not get new tokens, boot user to login
            if (tryRefresh) {
                const newTokens =
                    await AuthenticationService.refreshAccessToken();
                if (newTokens) tokenToCheck = newTokens.token;
                else return isRestored;
            }

            // log person into realm
            await realmSignIn(tokenToCheck);

            isRestored = true;
        } catch (error) {
            throw new Error(
                `Error restoring session. Error: ${JSON.stringify(error)}`
            );
        } finally {
            setRestoringSession(false);
        }

        return isRestored;
    };

    return [restoringSession, restoreSession];
};

export default useSession;

import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import SqWebRequest from '../../helpers/SqWebRequest';
import {
    GetAttachmentSasReturnType,
    IncidentMediaSas,
} from '../../types/Incident';
import useLifeCycle from '../utilities/useLifeCycle';

/**
 * hook that handles the fetching of shared access signatures
 * after fetching the Sas it will update the media object in place
 * @param medias
 * @param setIncidents - state dispatch
 * @returns tuple [fetching: boolean, error: boolean]
 */
const useSignedAttachments = (
    medias: IncidentMediaSas[]
): [boolean, boolean] => {
    const [fetchingSignatures, setFetchingSignatures] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [, , checkMountStateBeforeSetState] = useLifeCycle();

    /**
     * on mount go fetch the sas uris for medias
     * then update medias to include the sas uris
     */
    useEffect(() => {
        const fetchSignatures = async () => {
            checkMountStateBeforeSetState<boolean>(setFetchingSignatures, true);

            try {
                const payload = new URLSearchParams();
                medias.forEach(
                    ({
                        location,
                        locationThumbnail,
                        locationSigned,
                        locationThumbnailSigned,
                    }) => {
                        if (locationSigned && locationThumbnailSigned) return; // continue if already signed
                        if (!location || !locationThumbnail) return; // continue if urls are null
                        let param = JSON.stringify({
                            FullUri: location,
                            ThumbnailUri: locationThumbnail,
                        });
                        payload.append('uris[]', param);
                    }
                );

                const { status, data }: AxiosResponse =
                    await SqWebRequest().post<GetAttachmentSasReturnType[]>(
                        process.env.REACT_APP_GET_ATTACHMENT_SAS_URIS_URL!,
                        payload,
                        {
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                            },
                        }
                    );
                if (status === 200) {
                    if (Array.isArray(data)) {
                        // update the media objects in place
                        for (let i = 0; i < data.length; i++) {
                            let { FullUriAccess, ThumbnailUriAccess } = data[i];
                            let currentFromProp: IncidentMediaSas = JSON.parse(
                                JSON.stringify(medias[i])
                            );

                            currentFromProp.locationSigned = FullUriAccess;
                            currentFromProp.locationThumbnailSigned =
                                ThumbnailUriAccess;
                            medias[i] = currentFromProp;
                        }
                    }
                } else if (status === 500) {
                    throw new Error("Error retrieving Sas'");
                }
            } catch (error) {
                checkMountStateBeforeSetState<boolean>(setIsError, true);
            } finally {
                checkMountStateBeforeSetState<boolean>(
                    setFetchingSignatures,
                    false
                );
            }
        };

        if (medias.length > 0) fetchSignatures();
    }, [medias]);

    return [fetchingSignatures, isError];
};

export default useSignedAttachments;

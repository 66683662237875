/**
 * represents list of thumbnails component
 */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Image, Modal, ToastProps } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import '../../../content/css/ThumbnailList.css';
import useTokenCheck from '../../../hooks/authentication/useTokenCheck';
import useSignedAttachments from '../../../hooks/data/useSignedAttachments';
import { IncidentMediaSas } from '../../../types/Incident';
import { NotificationTheme } from '../../../types/notifications/Notifications';
import { SessionExpiredModal } from '../SessionExpiredModal/SessionExpiredModal';

type ThumbnailListProps = {
    imageSrcs: IncidentMediaSas[];
    max: number;
    addNotification?: (
        text: string,
        theme: NotificationTheme,
        toastProps: ToastProps | undefined
    ) => void;
};

export const ThumbnailList: FunctionComponent<ThumbnailListProps> = ({
    imageSrcs,
    max = 5,
    addNotification,
}) => {
    // states
    const [modalSrc, setModalSrc] = useState<IncidentMediaSas | undefined>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isTokenValid, isExpired] = useTokenCheck();
    const [fetchingSignatures, fetchSignatureError] =
        useSignedAttachments(imageSrcs);

    const imagesToShow = imageSrcs.slice(0, max);
    const imagesToConsolidate = imageSrcs.slice(max);

    useEffect(() => {
        if (addNotification && fetchSignatureError === true)
            addNotification(
                "Uh oh! We've encountered an issue retrieving your incidents' attachments. Please try again later.",
                NotificationTheme.error,
                {
                    autohide: true,
                    delay: 5000,
                }
            );
    }, [fetchingSignatures, fetchSignatureError]);

    const imageModal = () => {
        return (
            <Modal
                centered
                show={showModal}
                onHide={hideModal}
                size={'xl'}
                contentClassName="thumbnail_dialog_content"
            >
                <Modal.Header closeButton onClick={hideModal}></Modal.Header>
                <Modal.Body>
                    {/* I don't know why but having 70vh here and 75% on thumbnail_dialog_content works well responsively */}
                    <SimpleBar style={{ maxHeight: '70vh' }}>
                        <Image src={modalSrc?.locationSigned} fluid />
                    </SimpleBar>
                </Modal.Body>
            </Modal>
        );
    };

    const showFullSizeImage = async (src: IncidentMediaSas): Promise<void> => {
        if (await isTokenValid()) {
            setModalSrc(src);
            setShowModal(true);
        }
    };

    const hideModal = () => {
        setShowModal(false);
    };

    return (
        <React.Fragment>
            {fetchingSignatures ? (
                <div className="thumbnail_fetching_container">
                    Fetching attachments...
                </div>
            ) : (
                <div className="thumbnail_list_container">
                    {imagesToShow.map((src, index) => {
                        return (
                            <div
                                key={`${
                                    src._id?.toString() || uuidv4()
                                }-${index}`}
                                className="thumbnail"
                            >
                                <Image
                                    src={src.locationThumbnailSigned}
                                    fluid
                                    thumbnail
                                    onClick={() => showFullSizeImage(src)}
                                />
                            </div>
                        );
                    })}
                    {imagesToConsolidate.length > 0 ? (
                        <div className="thumbnail empty">
                            <span className="thumbnail_count_text">
                                +{imagesToConsolidate.length}
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            )}{' '}
            {imageModal()}
            <SessionExpiredModal show={isExpired} />
        </React.Fragment>
    );
};

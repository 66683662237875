import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { IncidentList } from '../../incidents/IncidentList/IncidentList';
import { Footer } from '../../common/Footer/Footer';
import { NavBar } from '../../common/NavBar/NavBar';
import '../../../content/css/App.css';
import useTokenCheck from '../../../hooks/authentication/useTokenCheck';
import { SessionExpiredModal } from '../../common/SessionExpiredModal/SessionExpiredModal';

export const IncidentPage: FunctionComponent = () => {
    const [isTokenValid, isExpired] = useTokenCheck();

    const navigateToAddIncident = async (): Promise<void> => {
        if (await isTokenValid())
            window.open(
                `${process.env.REACT_APP_FORMS_URL}/#!/incidents/add-edit/`,
                '_blank'
            );
    };

    return (
        <React.Fragment>
            <div style={{ height: '100%' }}>
                <NavBar data-testid="navbar" />
                <IncidentList />
                <Footer>
                    <button
                        className="footer_add_incident_button"
                        onClick={navigateToAddIncident}
                    >
                        <span data-testid="add-incident-btn">
                            Add incident
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="footer_button_arrow"
                            />
                        </span>
                    </button>
                </Footer>
            </div>
            <SessionExpiredModal show={isExpired} />
        </React.Fragment>
    );
};

export default IncidentPage;

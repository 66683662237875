/**
 * Hides text based on char length. Clicking component unhides text
 */
import { FunctionComponent, useState } from 'react';

type TextHiderTabProps = {
    minLength: number;
    text: string | null;
};

export const TextHider: FunctionComponent<TextHiderTabProps> = ({
    minLength,
    text,
}) => {
    const [hidden, setHidden] = useState(false);

    let displayText = text;

    if (displayText && displayText.length > minLength && !hidden)
        displayText = displayText.substring(0, minLength) + '...';

    return (
        <span
            onClick={() => {
                console.log('what');
                setHidden(!hidden);
            }}
        >
            {displayText}
        </span>
    );
};

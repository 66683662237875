import React, { FunctionComponent, useState, useEffect } from 'react';
import { Nav, Navbar, Offcanvas, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    FiberManualRecord as FiberManualRecordIcon,
    PlaylistAddCheck as PlaylistAddCheckIcon,
    SupervisorAccount as SupervisorAccountIcon,
    Visibility as VisibilityIcon,
    Home as HomeIcon,
} from '@mui/icons-material';
import {
    faBars,
    faCircle,
    faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';

import '../../../content/css/NavBar.css';
import { useAuth } from '../../../providers/AuthProvider';
import Colors from '../../../constants/Colors';
import useTokenCheck from '../../../hooks/authentication/useTokenCheck';
import {
    PAGE_URLS,
    WEBFORMS_URLS,
} from '../../../constants/NavigationConstants';
import { SessionExpiredModal } from '../SessionExpiredModal/SessionExpiredModal';
import getRealmApp from '../../../helpers/RealmApp';
import { LoadingSpinner } from '../../common/Loading/LoadingSpinner';

export const NavBar: FunctionComponent = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { signOut } = useAuth();
    const [isTokenValid, isExpired] = useTokenCheck();
    const { currentUser } = getRealmApp();

    const [showLoadingSpinner, setShowLoadingSpinner] =
        useState<boolean>(false);

    useEffect(() => {
        return () => {
            setShowLoadingSpinner(false);
        };
    }, []);

    const onSignOut = async () => {
        setShowLoadingSpinner(true);
        await signOut();
    };

    /**
     * on click of a navbar item, check if session is valid then navigate to the page
     * @param page
     */
    const onNavigate = async (page: string) => {
        if (await isTokenValid()) {
            let path = `/${page}`;

            if (path !== pathname) history.push(`/${page}`);
        }
    };

    /**
     * on click of a navbar item, redirect to webform page
     * @param page
     */
    const onRedirect = async (page: string) => {
        if (await isTokenValid()) {
            window.location.href = `${process.env.REACT_APP_FORMS_URL}/#!/${page}`;
        }
    };

    return (
        <React.Fragment>
            <Navbar
                collapseOnSelect
                expand={false}
                bg="dark-green"
                variant="dark"
            >
                <div className="navbar_menu_title">
                    <Navbar.Toggle
                        aria-controls="offcanvasNavbarLabel"
                        id="navbar_menu_toggle"
                    >
                        <FontAwesomeIcon icon={faBars} color={Colors.white} />
                    </Navbar.Toggle>
                    <span className="navbar_title">Incidents</span>
                </div>
                <Navbar.Offcanvas
                    id="offcanvas_navbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                >
                    <Offcanvas.Header id="offcanvas_navbar_header">
                        <Offcanvas.Title>Options</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body id="offcanvas_body">
                        <Nav>
                            <Nav.Link
                                eventKey="MAIN"
                                onClick={() => onRedirect(WEBFORMS_URLS.MAIN)}
                                className="offcanvas_nav_link"
                            >
                                <span>
                                    <HomeIcon /> Main
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                eventKey="INCIDENTS"
                                onClick={() =>
                                    onRedirect(WEBFORMS_URLS.INCIDENTS)
                                }
                                className="offcanvas_nav_link"
                            >
                                <span>
                                    <FiberManualRecordIcon /> Incidents
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                eventKey="ACTIONS"
                                onClick={() =>
                                    onRedirect(WEBFORMS_URLS.ACTIONS)
                                }
                                className="offcanvas_nav_link"
                            >
                                <span>
                                    <PlaylistAddCheckIcon /> Actions
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                eventKey="OBSERVATIONS"
                                onClick={() =>
                                    onRedirect(WEBFORMS_URLS.OBSERVATIONS)
                                }
                                className="offcanvas_nav_link"
                            >
                                <span>
                                    <VisibilityIcon /> Observations
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                eventKey="SQ"
                                onClick={() => onNavigate(PAGE_URLS.INCIDENTS)}
                                className="offcanvas_nav_link"
                            >
                                <span>
                                    <SupervisorAccountIcon /> Supervisor Queue
                                </span>
                            </Nav.Link>
                            <div className="offcanvas_footer">
                                <NavDropdown.Divider />
                                {currentUser && (
                                    <div className="offcanvas_user_info_container">
                                        <p className="offcanvas_user_label">
                                            Signed in as
                                        </p>
                                        <span className="offcanvas_user_value">
                                            {currentUser.profile.email}
                                        </span>
                                    </div>
                                )}
                                <Nav.Link
                                    eventKey="LOGOUT"
                                    onClick={onSignOut}
                                    className="offcanvas_nav_link logout"
                                >
                                    <FontAwesomeIcon
                                        icon={faSignOutAlt}
                                        size={'lg'}
                                        className="offcanvas_nav_link_icon"
                                    />
                                    <span>Sign out</span>
                                </Nav.Link>
                                <span className="version-number">
                                    {process.env.REACT_APP_VERSION_NAME}
                                </span>
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <SessionExpiredModal show={isExpired} />
            {showLoadingSpinner && (
                <LoadingSpinner block={true} message={'Signing out...'} />
            )}
        </React.Fragment>
    );
};

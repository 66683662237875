import React from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';

import {
    Notification,
    NotificationListProps,
} from '../../../types/notifications/Notifications';
import '../../../content/css/Notifications.css';

/**
 * Renders a notification list
 */
export const NotificationList: React.FunctionComponent<
    NotificationListProps
> = ({ position = 'top-end', notifications }) => {
    return (
        <ToastContainer position={position} className="notification_container">
            {notifications &&
                notifications.map(
                    ({
                        id,
                        headerText,
                        headerProps,
                        notificationText,
                        toastProps,
                        theme,
                    }: Notification) => {
                        return (
                            <Toast
                                {...toastProps}
                                key={id}
                                className={`notification ${theme}`}
                            >
                                {headerText && (
                                    <Toast.Header {...headerProps}>
                                        {headerText}
                                    </Toast.Header>
                                )}
                                <Toast.Body>{notificationText}</Toast.Body>
                            </Toast>
                        );
                    }
                )}
        </ToastContainer>
    );
};

/**
 * Custom Hook used to check the authentication expiry
 */
import { useState } from 'react';

import { useAuth } from '../../providers/AuthProvider';
import AuthenticationService from '../../services/AuthenticationService';
import { CustomJWT, TokenObject } from '../../types/Authentication';
import useLifeCycle from '../utilities/useLifeCycle';

function useTokenCheck(): [() => Promise<boolean>, boolean] {
    const { setToken, token } = useAuth();
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [refreshPromise, setRefreshPromise] = useState<Promise<
        TokenObject | undefined
    > | null>(null);
    const [, , checkMountStateBeforeSetState] = useLifeCycle();

    /**
     * Check token expiry. If valid, proceed with regular behaviour. If not valid, attempt to refresh token and proceed.
     * If new tokens not available, flag isExpired to true and show session expiry modal
     * @returns boolean
     */
    const isTokenValid = async (): Promise<boolean> => {
        const now: Date = new Date();
        let tokenExpiry: Date | undefined;
        let decodedCookieToken: CustomJWT | undefined;

        if (token)
            decodedCookieToken =
                AuthenticationService.getUserDataFromToken(token);

        if (decodedCookieToken && decodedCookieToken.exp)
            tokenExpiry = new Date(decodedCookieToken.exp * 1000);

        if (tokenExpiry && tokenExpiry > now) {
            checkMountStateBeforeSetState<boolean>(setIsExpired, false);
            return true;
        }

        let isRefreshedAndValidToken: boolean = await refreshTokenAndValidate(
            now
        );

        if (isRefreshedAndValidToken) return true;

        checkMountStateBeforeSetState<boolean>(setIsExpired, true);

        return false;
    };

    /**
     * Refresh token and check if new token expiry is greater than now
     * @param now
     * @returns
     */
    const refreshTokenAndValidate = async (now: Date): Promise<boolean> => {
        let tempRefreshPromise: Promise<TokenObject | undefined> | null = null;

        if (refreshPromise == null) {
            tempRefreshPromise = AuthenticationService.refreshAccessToken();
            checkMountStateBeforeSetState<Promise<
                TokenObject | undefined
            > | null>(setRefreshPromise, tempRefreshPromise);
        }

        try {
            let tokens: TokenObject | undefined = undefined;
            if (refreshPromise) tokens = await refreshPromise;
            else tokens = await tempRefreshPromise!;

            checkMountStateBeforeSetState<Promise<
                TokenObject | undefined
            > | null>(setRefreshPromise, null);

            if (tokens && tokens.expiry > now) {
                checkMountStateBeforeSetState<string | undefined>(
                    setToken,
                    tokens.token
                );
                setToken(tokens.token);
                checkMountStateBeforeSetState<boolean>(setIsExpired, false);
                return true;
            }
        } catch (err) {
            return false;
        }

        return false;
    };

    return [isTokenValid, isExpired];
}

export default useTokenCheck;

import React, { useContext, useEffect, useState } from 'react';
import * as Realm from 'realm-web';
import Cookies from 'universal-cookie';
import { COOKIE_KEYS } from '../constants/SQConstants';

import getRealmApp from '../helpers/RealmApp';
import useSecrets from '../hooks/data/useSecrets';
import AuthenticationService from '../services/AuthenticationService';
import type { AuthContextValue, TokenObject } from '../types/Authentication';
import { UserCustomDatum_organisation } from '../types/schemas/schema';

//Create a new Context object that will be provided to children of AuthProvider
const AuthContext = React.createContext<AuthContextValue>(
    {} as AuthContextValue
);

const AuthProvider = ({ children }: any) => {
    const cookies = new Cookies();

    // try set init value with cookie from token
    const [token, setToken] = useState<string | undefined>(
        cookies.get(COOKIE_KEYS.API_ACCESS_TOKEN)
    );
    const [orgId, setOrgId] = useState<number | undefined>();
    const [personId, setPersonId] = useState<string | undefined>();
    const [roles, setRoles] = useState<string[] | undefined>();
    const [retryLogin, setRetryLogin] = useState<boolean>(true);
    const [secrets, fetchingSecrets, fetchingSecretsError] = useSecrets();

    // sign into Supervisor Queue and set expiry
    const realmSignIn = async (jwtToken: string) => {
        const credentials = Realm.Credentials.jwt(jwtToken);

        await getRealmApp()
            .logIn(credentials)
            .then(() => {
                setToken(jwtToken);
                setRetryLogin(true);
            })
            .catch(async (err) => {
                // if login fails with a status 401, refresh the access token and retry logging in once again
                if (err.statusCode === 401 && retryLogin) {
                    try {
                        const tokens: TokenObject | undefined =
                            await AuthenticationService.refreshAccessToken();

                        if (tokens) {
                            setRetryLogin(false);
                            realmSignIn(tokens.token);
                        }
                    } catch (error: any) {
                        throw new Error(
                            'Could not refresh access Tokens: ' + error.Message
                        );
                    }
                } else {
                    throw new Error(
                        'Could not login to realm: ' + err.toString()
                    );
                }
            });
    };

    // sign user out of realm
    const realmSignOut = async (): Promise<void> => {
        const { currentUser } = getRealmApp();

        if (currentUser !== null) await currentUser.logOut();
    };

    // sign user out of ehs
    const ehsSignOut = (): void => {
        AuthenticationService.clearCookies();
    };

    const redirectToLogin = () => {
        window.location.replace(process.env.REACT_APP_FORMS_URL!);
    };

    // sign out realm
    // sign out ehs
    // redirect to forms login
    const signOut = async (): Promise<void> => {
        await realmSignOut();
        ehsSignOut();
        redirectToLogin();
    };

    // parse organisation json
    const parseOrganisation = (
        organisation: string
    ): UserCustomDatum_organisation => {
        let parsedOrg = JSON.parse(organisation);
        return {
            id: parsedOrg?.Id,
            name: parsedOrg.name,
        };
    };

    useEffect(() => {
        if (fetchingSecretsError) signOut();
    }, [fetchingSecretsError]);

    useEffect(() => {
        // get the current users org id
        // TODO: REFINE (We should be able to declare Realm.User.Profile type on getRealmApp but couldn't figure it out)
        const getUserInfo = (): {
            orgId: number | undefined;
            personId: string | undefined;
            roles: string[] | undefined;
        } => {
            let id: number | undefined,
                personId: string | undefined,
                roles: string[] | undefined;

            if (token) {
                // fall back to decoding token
                let customJWT =
                    AuthenticationService.getUserDataFromToken(token);
                personId = customJWT?.personId;
                roles = customJWT?.roles;

                if (customJWT?.organisation) {
                    ({ id } = parseOrganisation(customJWT.organisation));
                }
            }

            return {
                personId,
                orgId: id,
                roles,
            };
        };

        const { orgId, personId, roles } = getUserInfo();
        setOrgId(orgId);
        setPersonId(personId);
        setRoles(roles);
    }, [token]);

    return (
        <AuthContext.Provider
            value={{
                realmSignIn,
                realmSignOut,
                signOut,
                ehsSignOut,
                redirectToLogin,
                token,
                setToken,
                orgId,
                personId,
                roles,
                secrets,
                fetchingSecrets,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth === null)
        throw new Error('useAuth() used outside of an AuthProvider');

    return auth;
};

export { AuthProvider, AuthContext, useAuth };

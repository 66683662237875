import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingSpinner } from '../../common/Loading/LoadingSpinner';

import { useAuth } from '../../../providers/AuthProvider';
import { getFilters } from '../../../utils/IncidentFilters';
import { FilterTabs } from '../../common/FilterTabs/FilterTabs';
import { IncidentTable } from '../IncidentTable/IncidentTable';
import '../../../content/css/App.css';
import IncidentService from '../../../services/IncidentService';
import { Incident, IncidentJSON } from '../../../types/Incident';
import { QueryHelper } from '../../../helpers/graphql/GraphQLHelper';
import {
    IncidentCountObject,
    IncidentsWithCountReturnType,
} from '../../../types/schemas/graphql/graphql-schema';
import {
    INCIDENT_STATUSES,
    INCIDENT_TABLE_QUERY_PARAMS,
    FORM_ROLES,
} from '../../../constants/SQConstants';
import { updateIncidentStatus } from '../../../helpers/graphql/GraphQLQueries';
import useTokenCheck from '../../../hooks/authentication/useTokenCheck';
import { SessionExpiredModal } from '../../common/SessionExpiredModal/SessionExpiredModal';
import useLifeCycle from '../../../hooks/utilities/useLifeCycle';
import useNotifications from '../../../hooks/notifications/useNotifications';
import { NotificationTheme } from '../../../types/notifications/Notifications';
import { NotificationList } from '../../common/Notifications/NotificationList';
import useQueryParams from '../../../hooks/navigation/useQueryParams';

const INITIAL_COUNT: IncidentCountObject = {
    needsAttentionCount: 0,
    newIncidentsCount: 0,
    pageCount: 1,
};

export const IncidentList: FunctionComponent = () => {
    const { orgId, personId, roles } = useAuth();
    const PAGE_SIZE: number = 10;

    // state
    const [queryParams, addQueryParam] = useQueryParams();
    const queryParamTab = queryParams.get(INCIDENT_TABLE_QUERY_PARAMS.TAB);
    const queryParamPage = queryParams.get(INCIDENT_TABLE_QUERY_PARAMS.PAGE_ID);
    const queryParamSortField = queryParams.get(
        INCIDENT_TABLE_QUERY_PARAMS.SORT_FIELD
    );
    const queryParamSortAsc = queryParams.get(
        INCIDENT_TABLE_QUERY_PARAMS.SORT_ASC
    );

    const [selectedTabId, setSelectedTabId] = useState<string>(
        // make sure tab exists
        (INCIDENT_STATUSES.ALL_IDS.find(
            (id: string) => id.toUpperCase() === queryParamTab?.toUpperCase()
        ) &&
            queryParamTab) ||
            'all'
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [fetching, setFetching] = useState<boolean>(false);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [incidentCount, setIncidentCount] =
        useState<IncidentCountObject>(INITIAL_COUNT);
    const [isTokenValid, isExpired] = useTokenCheck();
    const [pageIndex, setPageIndex] = useState<number>(
        Number(queryParamPage) || 0
    );
    const [sortField, setSortField] = useState<string>(
        queryParamSortField || 'occurenceDateTime'
    );
    const [sortAscending, setSortAscending] = useState<boolean>(
        queryParamSortAsc === 'true'
    );
    const [notifications, addNotification] = useNotifications();

    // graphql hooks
    // get query
    const query = QueryHelper.getQuery(
        selectedTabId,
        orgId || -1,
        pageIndex,
        PAGE_SIZE,
        sortField,
        sortAscending,
        roles?.includes(FORM_ROLES.SUPERVISOR) && personId ? personId : ''
    );

    // mutation to set incident status
    const [setIncidentStatus] = useMutation(updateIncidentStatus, {
        refetchQueries: [query],
    });

    /**
     * Adds an error notification
     */
    const sendCantRetrieveIncidentsNotification = () => {
        addNotification(
            "Uh oh! We've encountered an issue retrieving your incidents. Please try again later.",
            NotificationTheme.error,
            {
                autohide: true,
                delay: 5000,
            }
        );
    };

    /**
     * Update url when tab/page changes
     */
    useEffect(() => {
        updateQueryParams();
    }, [selectedTabId, pageIndex, sortAscending, sortField]);

    /**
     * Mount data fetch
     */
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (loading) getData();
    }, [loading]);

    /**
     * Show needs attention tab if it contains incidents on first load
     */
    useEffect(() => {
        if (firstLoad && incidentCount.needsAttentionCount > 1) {
            setFirstLoad(false);
            setSelectedTabId(INCIDENT_STATUSES.NEEDS_ATTENTION.id);
            setPageIndex(0);
            setLoading(true);
        } else setLoading(false);
    }, [incidentCount]);

    /**
     * GraphQL get paginated incidents query
     */
    const [getData] = useLazyQuery<IncidentsWithCountReturnType>(query, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        nextFetchPolicy: 'cache-first',
        onError: (err) => {
            setLoading(false);
            setIncidents([]);
            setIncidentCount((prev) => ({ ...prev, pageCount: 1 }));
            sendCantRetrieveIncidentsNotification();
        },
        onCompleted: ({
            getPaginatedIncidents,
            getCountOfIncidents,
        }: IncidentsWithCountReturnType) => {
            try {
                onQueryComplete(getPaginatedIncidents, getCountOfIncidents);
            } catch (error) {
                setIncidents([]);
                sendCantRetrieveIncidentsNotification();
            } finally {
                if (!firstLoad) setLoading(false);

                if (fetching) setFetching(false);
            }
        },
    });

    /**
     * transform incidents and set the updated incident counts once query is completed
     * @param orgIncidents
     * @param getCountOfIncidentsByStatus
     */
    const onQueryComplete = (
        orgIncidents: IncidentJSON[],
        getCountOfIncidents: IncidentCountObject
    ): void => {
        setIncidentCount(getCountOfIncidents);
        transformIncidentJSON(orgIncidents);
    };

    // user selects a filter
    const filterClick = async (id: string): Promise<void> => {
        const same: boolean = id.toLowerCase() === selectedTabId.toLowerCase();

        if (!same && (await isTokenValid())) {
            setPageIndex(0);
            setLoading(true);
            setSelectedTabId(id);
        }
    };

    // user selects a sort option
    const sortClick = async (newSortField: string): Promise<void> => {
        if ((await isTokenValid()) && newSortField) {
            let newSortDirection =
                newSortField.toLowerCase() === sortField.toLowerCase()
                    ? !sortAscending
                    : sortAscending;
            setPageIndex(0);
            setLoading(true);
            setSortField(newSortField);
            setSortAscending(newSortDirection);
        }
    };

    // update url with query params
    const updateQueryParams = () => {
        let queryParams = new URLSearchParams();
        queryParams.append(INCIDENT_TABLE_QUERY_PARAMS.TAB, selectedTabId);
        queryParams.append(
            INCIDENT_TABLE_QUERY_PARAMS.PAGE_ID,
            pageIndex.toString()
        );
        queryParams.append(
            INCIDENT_TABLE_QUERY_PARAMS.SORT_ASC,
            sortAscending.toString()
        );
        queryParams.append(INCIDENT_TABLE_QUERY_PARAMS.SORT_FIELD, sortField);

        addQueryParam(`?${queryParams.toString()}`);
    };

    // get paginated incidents based upon page index
    const fetchPaginationData = async (newPageIndex: number): Promise<void> => {
        if (await isTokenValid()) {
            setPageIndex(newPageIndex);
            setLoading(true);
        }
    };

    const onIncidentArchived = async (incidentId: string): Promise<void> => {
        const incident: Incident | undefined = incidents.find(
            (inc) => inc.Id?.toUpperCase() === incidentId.toUpperCase()
        );

        if (!incident) return;

        const normalizedIncidentStatus = incident.IncidentStatus?.toLowerCase();
        if (
            // only change status to viewed if status is Needs attention otherwise status should stay the same
            incident.Id &&
            incident.IncidentStatus &&
            normalizedIncidentStatus ===
                INCIDENT_STATUSES.COMPLETED.value.toLowerCase()
        ) {
            const statusObject = {
                id: incidentId,
                status: INCIDENT_STATUSES.ARCHIVED.value,
            };

            setFetching(true);
            await setIncidentStatus({
                variables: { input: statusObject },
            });
        }
    };

    // handle incident action clicked
    const onIncidentViewed = async (incidentId: string): Promise<void> => {
        if ((await isTokenValid()) && incidents) {
            const incident: Incident | undefined = incidents.find(
                (inc) => inc.Id?.toUpperCase() === incidentId.toUpperCase()
            );

            if (!incident) return;

            window.open(
                `${process.env.REACT_APP_FORMS_URL}/#!/incidents/add-edit-sq/${incident.Id}`
            );

            const normalizedIncidentStatus =
                incident.IncidentStatus?.toLowerCase();
            if (
                // only change status to viewed if status is Needs attention otherwise status should stay the same
                incident.Id &&
                incident.IncidentStatus &&
                normalizedIncidentStatus ===
                    INCIDENT_STATUSES.NEEDS_ATTENTION.value.toLowerCase()
            ) {
                const statusObject = {
                    id: incidentId,
                    status: INCIDENT_STATUSES.VIEWED.value,
                };

                await setIncidentStatus({
                    variables: { input: statusObject },
                });
            }
        }
    };

    /**
     * Transform the incidentJSON retreived from Mongo to be used in SQ
     * @param orgIncidents
     */
    const transformIncidentJSON = (orgIncidents: IncidentJSON[]): void => {
        // if user org id is undefined do not show incidents and let them know an error has occurred
        if (orgId === undefined) {
            sendCantRetrieveIncidentsNotification();
            return;
        }

        if (orgIncidents.length > 0) {
            let results = IncidentService.transformIncidents(orgIncidents);
            setIncidents(results);
        } else {
            setIncidents([]);
        }
    };

    return (
        <React.Fragment>
            <NotificationList
                position={'top-end'}
                notifications={notifications}
            />
            <FilterTabs
                tabs={getFilters(
                    incidentCount.needsAttentionCount,
                    incidentCount.newIncidentsCount
                )}
                onClick={filterClick}
                selectedTabId={selectedTabId}
            />
            {fetching ? (
                <LoadingSpinner block={true} message={'Loading...'} />
            ) : (
                <IncidentTable
                    loading={loading}
                    onSortClick={sortClick}
                    pageCount={incidentCount.pageCount}
                    pageSize={PAGE_SIZE}
                    currentPage={pageIndex}
                    onPagination={fetchPaginationData}
                    sortField={sortField}
                    sortAscending={sortAscending}
                    onIncidentViewed={onIncidentViewed}
                    onIncidentArchived={onIncidentArchived}
                    incidents={incidents}
                />
            )}
            <SessionExpiredModal show={isExpired} />
        </React.Fragment>
    );
};

import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

import { PaginationProps } from '../../../types/props/PaginationProps';
import '../../../content/css/Pagination.css';

/**
 * Custom pagination component
 */
export const Pagination: React.FunctionComponent<PaginationProps> = ({
    onPagination,
    currentPage,
    pageCount,
}) => {
    /**
     * helper to render the page number buttons
     */
    const getPaginationItems = (): React.ReactChild => {
        const PAGE_RANGE = 2;
        let items = [];

        if (currentPage - PAGE_RANGE > 0) {
            items.push(
                <BootstrapPagination.Item
                    key={'paginationIndex' + (currentPage - PAGE_RANGE - 1)}
                    active={false}
                    onClick={() => {
                        onPagination(currentPage - PAGE_RANGE - 1);
                    }}
                >
                    ...
                </BootstrapPagination.Item>
            );
        }

        for (
            let i = currentPage - PAGE_RANGE;
            i <= currentPage + PAGE_RANGE;
            i++
        ) {
            if (i >= 0 && i < pageCount)
                items.push(
                    <BootstrapPagination.Item
                        key={'paginationIndex' + i}
                        active={currentPage === i}
                        onClick={() => {
                            onPagination(i);
                        }}
                    >
                        {i + 1}
                    </BootstrapPagination.Item>
                );
        }

        if (currentPage + PAGE_RANGE < pageCount) {
            items.push(
                <BootstrapPagination.Item
                    key={'paginationIndex' + (currentPage + PAGE_RANGE + 1)}
                    active={false}
                    onClick={() => {
                        onPagination(currentPage + PAGE_RANGE + 1);
                    }}
                >
                    ...
                </BootstrapPagination.Item>
            );
        }

        return <React.Fragment>{items}</React.Fragment>;

        return (
            <React.Fragment>
                {[...Array(pageCount)].map((_, i) => (
                    <BootstrapPagination.Item
                        key={'paginationIndex' + i}
                        active={currentPage === i}
                        onClick={() => {
                            onPagination(i);
                        }}
                    >
                        {i + 1}
                    </BootstrapPagination.Item>
                ))}
            </React.Fragment>
        );
    };

    return (
        <BootstrapPagination>
            <BootstrapPagination.First
                onClick={() => {
                    onPagination(0);
                }}
                disabled={currentPage === 0}
            />
            <BootstrapPagination.Prev
                onClick={() => onPagination(currentPage - 1)}
                disabled={currentPage === 0}
            />
            {getPaginationItems()}
            <BootstrapPagination.Next
                onClick={() => {
                    onPagination(currentPage + 1);
                }}
                disabled={currentPage === pageCount - 1}
            />
            <BootstrapPagination.Last
                onClick={() => onPagination(pageCount - 1)}
                disabled={currentPage === pageCount - 1}
            />
        </BootstrapPagination>
    );
};

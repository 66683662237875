import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import IncidentPage from './components/pages/IncidentPage/IncidentPage';
import './content/css/App.css';
import { LoadingSpinner } from './components/common/Loading/LoadingSpinner';
import useSession from './hooks/authentication/useSession';
import { useAuth } from './providers/AuthProvider';
import useLifeCycle from './hooks/utilities/useLifeCycle';
import { ProtectedRoute } from './components/common/ProtectedRoute/ProtectedRoute';

export const App: React.FunctionComponent = () => {
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [, restoreSession] = useSession();
    const [loading, setLoading] = useState<boolean>(true);
    const { token, secrets, signOut } = useAuth();
    const [, isMounted] = useLifeCycle();
    const history = useHistory();
    const { pathname } = useLocation();

    /**
     * Attempt to restore session
     */
    useEffect(() => {
        const init = async () => {
            try {
                const restored = await restoreSession();
                if (isMounted) setAuthorized(restored);

                // restore did not go successful, sign user out
                if (!restored) signOut();
            } catch (error) {
                signOut();
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        if (!token) signOut();
        else if (token && secrets) init();
    }, [isMounted, token, secrets]);

    /**
     * go to incidents once authorized
     */
    useEffect(() => {
        // to preserve url params only change url if we're at index
        if (authorized === true && pathname === '/') history.push('/incidents');
    }, [authorized]);

    return (
        <>
            {loading ? (
                <LoadingSpinner block={true} message={'Loading...'} />
            ) : (
                <Switch>
                    <ProtectedRoute
                        exact
                        authorized={authorized}
                        path={'/incidents'}
                        component={IncidentPage}
                    />
                </Switch>
            )}
        </>
    );
};

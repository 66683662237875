import React, { FunctionComponent, useCallback } from 'react';
import { Table, ToastProps } from 'react-bootstrap';
import { useTable, usePagination, Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarker,
    faUser,
    faSort,
    faSortDown,
    faSortUp,
} from '@fortawesome/free-solid-svg-icons';

import { ThumbnailList } from '../../common/ThumbnailList/ThumbnailList';
import {
    Incident,
    IncidentLocationAndPersonInvolved,
    IncidentGeneralInfo,
} from '../../../types/Incident';
import {
    getDateStringShortMonth,
    getShortTimeString,
    isDateNew,
} from '../../../utils/DateUtils';
import { INCIDENT_STATUSES } from '../../../constants/SQConstants';
import '../../../content/css/IncidentTable.css';
import { PaginationProps } from '../../../types/props/PaginationProps';
import { Pagination } from '../../common/Pagination/Pagination';
import { SpinnerCircularFixed } from 'spinners-react';
import Colors from '../../../constants/Colors';
import { NotificationTheme } from '../../../types/notifications/Notifications';
import { TextHider } from '../../common/TextHider/TextHider';

type IncidentTableProps = {
    loading?: boolean;
    onSortClick(sortField: string | null): void;
    pageCount: number;
    pageSize?: number;
    currentPage: number;
    onPagination(currentPage: number): void;
    sortAscending: boolean;
    sortField: string;
    incidents: Incident[];
    onIncidentViewed: (incidentId: string) => void;
    onIncidentArchived: (incidentId: string) => void;
    addNotification?: (
        text: string,
        theme: NotificationTheme,
        toastProps: ToastProps | undefined
    ) => void;
};

export const IncidentTable: FunctionComponent<IncidentTableProps> = ({
    pageCount,
    onSortClick,
    pageSize = 10,
    currentPage,
    onPagination,
    sortAscending,
    sortField,
    incidents,
    onIncidentViewed,
    onIncidentArchived,
    loading,
}) => {
    // get column header ui
    const getHeader = (label: string): JSX.Element => (
        <span className="incident_table_light_text">{label}</span>
    );

    //Get sort icon, grey up down icon if inactive. Black up/down for the active sort direction
    const getSortIcon = (active: boolean): JSX.Element => {
        var icon = (
            <FontAwesomeIcon icon={faSort} size={'1x'} color={'lightgray'} />
        );

        if (active) {
            icon = (
                <>
                    <FontAwesomeIcon
                        icon={faSortUp}
                        size={'1x'}
                        className={sortAscending ? 'dark_green' : 'light_gray'}
                    />
                    <FontAwesomeIcon
                        icon={faSortDown}
                        size={'1x'}
                        className={[
                            'sort_active_down',
                            !sortAscending ? 'dark_green' : 'light_gray',
                        ].join(' ')}
                    />
                </>
            );
        }

        return icon;
    };

    /**
     * Helper function to get a display title for an incident
     * - try incident title and then fallback on ${type} at ${project/site}
     */
    const getIncidentTitleDisplayName = useCallback(
        ({
            IncidentGeneralInfo: { IncidentTitle, IncidentType },
            IncidentLocationAndPersonInvolved,
        }: Incident): string => {
            let result = IncidentTitle;
            if (!result) {
                result = `${IncidentType?.name} ${
                    IncidentLocationAndPersonInvolved.IncidentProject &&
                    IncidentLocationAndPersonInvolved.IncidentProject.name &&
                    IncidentLocationAndPersonInvolved.IncidentProject.name.toLowerCase() !==
                        'none'
                        ? ` at ${IncidentLocationAndPersonInvolved.IncidentProject.name}`
                        : ''
                }`;
            }

            return result;
        },
        []
    );

    const columns = React.useMemo((): Column<Incident>[] => {
        const getBadgeColor = (status: string): string => {
            let result: string = '';

            if (status) {
                switch (status.toLowerCase()) {
                    case INCIDENT_STATUSES.NEEDS_ATTENTION.value.toLowerCase():
                        result = 'yellow';
                        break;
                    case INCIDENT_STATUSES.COMPLETED.id:
                        result = 'green';
                        break;
                    case INCIDENT_STATUSES.ARCHIVED.id:
                        result = 'gray';
                        break;
                    case INCIDENT_STATUSES.VIEWED.id:
                    default:
                        result = 'blue';
                        break;
                }
            }

            return result;
        };

        return [
            {
                Header: '',
                id: 'incidentId',
                accessor: 'IncidentId',
            },
            {
                Header: getHeader('ID'),
                id: 'customerIncidentId',
                accessor: (originalRow: Incident) =>
                    originalRow.IncidentGeneralInfo.CustomerIncidentId,
                Cell: (props: any) => {
                    return (
                        <React.Fragment>
                            <div className="incident_table_light_text">
                                {
                                    //Show 5 last characters only
                                    props.value.substring(
                                        props.value.length - 6,
                                        props.value.length - 1
                                    )
                                }
                            </div>
                            {isDateNew(props.row.original.IncidentDateTime) &&
                            props.row.original.IncidentStatus &&
                            props.row.original.IncidentStatus?.toLowerCase() !==
                                INCIDENT_STATUSES.VIEWED.id &&
                            props.row.original.IncidentStatus?.toLowerCase() !==
                                INCIDENT_STATUSES.COMPLETED.id ? (
                                <span className="incident_table_status_badge mini light_blue">
                                    NEW
                                </span>
                            ) : (
                                ''
                            )}
                        </React.Fragment>
                    );
                },
            },
            {
                Header: getHeader('Incident type'),
                accessor: 'IncidentGeneralInfo', // accessor is the 'key' in the data
                Cell: ({
                    value: {
                        IncidentClassifications,
                        IncidentDescription,
                        IncidentType,
                    },
                    row,
                }: {
                    value: IncidentGeneralInfo;
                    row: { original: Incident };
                }) => {
                    return (
                        <React.Fragment>
                            <div
                                className="incident_table_strong_text"
                                data-testid="incident-title"
                            >
                                <TextHider
                                    minLength={45}
                                    text={getIncidentTitleDisplayName(
                                        row.original
                                    )}
                                />
                            </div>
                            <div className="incident_table_classifications_container">
                                {IncidentClassifications &&
                                IncidentClassifications.length > 0 ? (
                                    IncidentClassifications.map(
                                        (
                                            classificationName: string,
                                            idx: number
                                        ) =>
                                            classificationName && (
                                                <span
                                                    key={`${row.original.Id}_classification_${idx}`}
                                                    className={
                                                        'incident_table_classifications_badge default ' +
                                                        IncidentType?.name
                                                            ?.toLowerCase()
                                                            .replace(' ', '_')
                                                    }
                                                >
                                                    {classificationName.toUpperCase()}
                                                </span>
                                            )
                                    )
                                ) : (
                                    <span className="incident_table_classifications_badge">
                                        UNCLASSIFIED
                                    </span>
                                )}
                            </div>
                            <ThumbnailList
                                imageSrcs={row.original.IncidentImages}
                                max={5}
                            />
                            <div className="incident_table_description_container">
                                {IncidentDescription}
                            </div>
                        </React.Fragment>
                    );
                },
            },
            {
                Header: getHeader('Date'),
                accessor: 'IncidentDateTime',
                Cell: ({
                    value,
                    row,
                }: {
                    value: Date;
                    row: { original: Incident };
                }) => {

                    var localDate = new Date(value);

                    if (value)
                        localDate = new Date(
                            localDate.getTime() +
                                localDate.getTimezoneOffset() * 60 * 1000
                        );

                    return (
                        <React.Fragment>
                            {row.original.IncidentStatus ? (
                                <div className="incident_table_date_container">
                                    <div className="bold font-16">
                                        {getDateStringShortMonth(localDate)}
                                    </div>
                                    <div className="incident_table_med_text">
                                        {getShortTimeString(localDate)}
                                    </div>
                                </div>
                            ) : (
                                <div className="empty-table-column"></div>
                            )}
                        </React.Fragment>
                    );
                },
            },
            {
                Header: getHeader('Project'),
                accessor: 'IncidentLocationAndPersonInvolved',
                Cell: ({
                    value,
                    row,
                }: {
                    value: IncidentLocationAndPersonInvolved;
                    row: { original: Incident };
                }) => {
                    return (
                        <React.Fragment>
                            {row.original.Id ? (
                                <div>
                                    <div className="bold font-16 word_break">
                                        <FontAwesomeIcon
                                            icon={faMapMarker}
                                            className="incident_table_icons map-marker"
                                        />
                                        {value.IncidentProject &&
                                        value.IncidentProject.name?.toLowerCase() !==
                                            'none' ? (
                                            <TextHider
                                                minLength={15}
                                                text={
                                                    value.IncidentProject.name!
                                                }
                                            />
                                        ) : (
                                            <span
                                                className="incident_table_user_none_added"
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 14,
                                                }}
                                            >
                                                No project added
                                            </span>
                                        )}
                                    </div>
                                    <div className="incident_table_med_text">
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            className="incident_table_icons user"
                                        />
                                        {value.PersonInvolved?.firstName &&
                                        value.PersonInvolved?.lastName &&
                                        value.PersonInvolved?.firstName.toLowerCase() !==
                                            'none' &&
                                        value.PersonInvolved?.lastName.toLowerCase() !==
                                            'none' ? (
                                            value.PersonInvolved.firstName +
                                            ' ' +
                                            value.PersonInvolved.lastName
                                        ) : (
                                            <div className="incident_table_user_none_added">
                                                No person added
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="empty-table-column"></div>
                            )}
                        </React.Fragment>
                    );
                },
            },
            {
                Header: getHeader('Status'),
                accessor: 'IncidentStatus',
                Cell: ({ value }: { value: string }) => {
                    let color: string = getBadgeColor(value);
                    return (
                        <div className="incident_table_status_badge_container">
                            <div
                                className={
                                    'incident_table_status_badge ' + color
                                }
                            >
                                {value ? value.toUpperCase() : ''}
                            </div>
                        </div>
                    );
                },
            },
            {
                id: 'action-column',
                Cell: (props: any) => (
                    <div className="incident_table_action_container">
                        <button
                            className="incident_table_edit"
                            hidden={
                                props.row.original.IncidentStatus ? false : true
                            }
                            onClick={(evt) => {
                                const id = props.row.original.Id;
                                onIncidentViewed(id);
                            }}
                        >
                            {' '}
                            {props.row.original.IncidentStatus &&
                            (props.row.original.IncidentStatus.toLowerCase() ===
                                INCIDENT_STATUSES.COMPLETED.id ||
                                props.row.original.IncidentStatus.toLowerCase() ===
                                    INCIDENT_STATUSES.ARCHIVED.id)
                                ? 'View'
                                : 'Edit'}
                        </button>
                        <button
                            className="incident_table_archive"
                            hidden={
                                props.row.original.IncidentStatus.toLowerCase() !==
                                INCIDENT_STATUSES.COMPLETED.id
                                    ? true
                                    : false
                            }
                            onClick={(evt) => {
                                const id = props.row.original.Id;
                                onIncidentArchived(id);
                            }}
                        >
                            Archive
                        </button>
                    </div>
                ),
            },
        ];
    }, [onIncidentViewed]);

    const tableInstance = useTable(
        {
            columns,
            data: incidents,
            initialState: {
                pageIndex: currentPage,
                pageSize: pageSize,
                hiddenColumns: ['incidentId'],
            },
            manualPagination: true,
        },
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        prepareRow,
    } = tableInstance;

    const getPaginationProps = (): PaginationProps => ({
        canPreviousPage,
        onPagination,
        currentPage,
        pageCount,
    });

    //Get the mongo sort field name from the header name of the ReactTable
    const getSortFieldName = (headerName: string) => {
        if (headerName.toLowerCase() === 'IncidentGeneralInfo'.toLowerCase())
            return 'type.name';
        else if (headerName.toLowerCase() === 'IncidentStatus'.toLowerCase())
            return 'status.name';
        else if (headerName.toLowerCase() === 'IncidentDateTime'.toLowerCase())
            return 'occurenceDateTime';
        else if (
            headerName.toLowerCase() ===
            'IncidentLocationAndPersonInvolved'.toLowerCase()
        )
            return 'project.name';
        return null;
    };

    return (
        <div className="incident_table_container">
            <div className="incident_table_child">
                <Table {...getTableProps()} borderless>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        onClick={() => {
                                            onSortClick(
                                                getSortFieldName(column.id)
                                            );
                                        }}
                                    >
                                        {column.render('Header')}
                                        <span className="sort_arrow">
                                            {getSortFieldName(column.id) &&
                                                getSortIcon(
                                                    sortField.toLowerCase() ===
                                                        getSortFieldName(
                                                            column.id
                                                        )?.toLowerCase()
                                                )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {loading === true ? (
                            <tr>
                                <td colSpan={columns.length}>
                                    <div className="incident_table_no_records_container">
                                        <SpinnerCircularFixed
                                            enabled={true}
                                            color={Colors.darkGreen}
                                            secondaryColor={Colors.lightGrey}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ) : page && page.length > 0 ? (
                            page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className={
                                            'incident_table_row ' +
                                            (row.original.IncidentStatus &&
                                            row.original.IncidentStatus.toUpperCase() ===
                                                'NEEDS ATTENTION'
                                                ? 'yellow'
                                                : '')
                                        }
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={columns.length}>
                                    <div className="incident_table_no_records_container">
                                        <span className="incident_table_light_text">
                                            No records found
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <div className="incident_table_pagination_container">
                    <Pagination {...getPaginationProps()} />
                </div>
            </div>
        </div>
    );
};

import { useEffect, useRef, useState } from 'react';

import SqWebRequest from '../../helpers/SqWebRequest';
import { COOKIE_KEYS, SECRET_NAMES } from '../../constants/SQConstants';
import { useAuth } from '../../providers/AuthProvider';
import Cookies from 'universal-cookie';
import axios from 'axios';

/**
 * useSecrets
 * - hook to grab secrets from api
 */
const useSecrets = (): [Record<string, string> | undefined, boolean, any] => {
    // using ref here so we don't re-render on changes to secrets
    const secrets = useRef<Record<string, string>>();
    const [fetchingSecrets, setFetchingSecrets] = useState<boolean>(false);
    const [fetchSecretsError, setFetchSecretsError] =
        useState<Error | undefined>();
    const cookies = new Cookies();

    const { token } = useAuth();

    useEffect(() => {
        const getSecrets = async () => {
            try {
                const tokenToCheck: string | undefined =
                    token || cookies.get(COOKIE_KEYS.API_ACCESS_TOKEN);

                if (!tokenToCheck) return;

                setFetchingSecrets(true);
                let payload = new URLSearchParams();
                Object.keys(SECRET_NAMES).forEach(
                    (key: string, index: number) => {
                        payload.append(
                            'names[' + index + ']',
                            SECRET_NAMES[key]
                        );
                    }
                );

                const { data } = await SqWebRequest().request<
                    Record<string, string>
                >({
                    url: process.env.REACT_APP_SECRETS_URL,
                    method: 'POST',
                    data: payload,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });

                if (!data) setFetchSecretsError(new Error('NO_SECRET_RESULTS'));
                secrets.current = data;
            } catch (error: any) {
                // if any error happend with secrets request, we can no longer authenticate users and they should be signed out
                if (error && axios.isAxiosError(error))
                    setFetchSecretsError(error);
            } finally {
                setFetchingSecrets(false);
            }
        };

        getSecrets();
    }, [token]);

    return [secrets.current, fetchingSecrets, fetchSecretsError];
};

export default useSecrets;

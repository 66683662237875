import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';
import jwt from 'jwt-decode';

import { CustomJWT, TokenObject } from '../types/Authentication';
import { IAuthenticationService } from './interfaces/IAuthenticationService';
import { COOKIE_KEYS } from '../constants/SQConstants';

// TODO: MIGRATE TO HOOK
export class AuthenticationService implements IAuthenticationService {
    cookies: Cookies;
    repeatedOnce: boolean;
    refreshPromise: Promise<TokenObject | undefined> | null;
    constructor() {
        this.cookies = new Cookies();
        this.repeatedOnce = false;
        this.refreshPromise = null;
    }

    /**
     * Sets auth cookies
     * @param token
     * @param refreshToken
     */
    private _setCookies = (
        token: string | null,
        refreshToken: string | null
    ): void => {
        this.cookies.set(COOKIE_KEYS.API_ACCESS_TOKEN, token, {
            secure: true,
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.set(COOKIE_KEYS.API_REFRESH_TOKEN, refreshToken, {
            secure: true,
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
    };

    /**
     * Clear all cookies associated with this app
     */
    clearCookies(): void {
        this.cookies.remove(COOKIE_KEYS.API_ACCESS_TOKEN, {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.remove(COOKIE_KEYS.API_REFRESH_TOKEN, {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.remove('token', {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.remove('tokenExpiryDate', {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.remove('refreshToken', {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
        this.cookies.remove('incident', {
            path: '/',
            domain: process.env.REACT_APP_COOKIES_DOMAIN,
        });
    }

    /**
     * Get cookies and parse expiry date from jwt
     * @returns TokenObject or undefined
     */
    getCookies = (): TokenObject | undefined => {
        const token = this.cookies.get(COOKIE_KEYS.API_ACCESS_TOKEN);
        const refreshToken = this.cookies.get(COOKIE_KEYS.API_REFRESH_TOKEN);

        const decodedTokenCookies: CustomJWT | undefined =
            this.getUserDataFromToken(token);

        let tokenObject: TokenObject | undefined;

        if (decodedTokenCookies && decodedTokenCookies.exp)
            tokenObject = {
                token,
                refreshToken,
                expiry: new Date(decodedTokenCookies.exp * 1000),
            };

        return tokenObject;
    };

    /**
     * Checks if api_access_token cookie is set
     * @returns if it is true, else false
     */
    checkCookiesSet = (): boolean => {
        const accessToken: string = this.cookies.get(
            COOKIE_KEYS.API_ACCESS_TOKEN
        );

        return accessToken !== null;
    };

    /**
     * Refresh user access token
     * @returns the new tokens or undefined
     */
    refreshAccessToken = (): Promise<TokenObject | undefined> => {
        const self = this;
        const refreshToken: string = this.cookies.get(
            COOKIE_KEYS.API_REFRESH_TOKEN
        );
        const url: string = process.env.REACT_APP_TOKEN_URL!;
        const params: URLSearchParams = new URLSearchParams();
        params.append('refresh_token', refreshToken);
        params.append('grant_type', 'refresh_token');
        params.append('client_id', process.env.REACT_APP_CLIENT_ID!);
        params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET!);

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        if (this.refreshPromise == null) {
            let refreshPromise = new Promise<TokenObject | undefined>(
                (resolve, reject) => {
                    axios
                        .post<AxiosRequestConfig, AxiosResponse>(
                            url,
                            params.toString(),
                            config
                        )
                        .then(({ data }) => {
                            let tokens: TokenObject | undefined;
                            self._setCookies(
                                data.access_token,
                                data.refresh_token
                            );

                            const decodedTokenCookies: CustomJWT | undefined =
                                self.getUserDataFromToken(data.access_token);

                            if (
                                decodedTokenCookies &&
                                decodedTokenCookies.exp
                            ) {
                                tokens = {
                                    token: data.access_token,
                                    refreshToken: data.refresh_token,
                                    expiry: new Date(
                                        decodedTokenCookies.exp * 1000
                                    ),
                                };
                            } else
                                reject(
                                    new Error('Could not parse access token')
                                );

                            resolve(tokens);
                        })
                        .catch((err) => {
                            reject(err);
                        })
                        .finally(() => {
                            this.refreshPromise = null;
                        });
                }
            );
            this.refreshPromise = refreshPromise;
        }

        return this.refreshPromise;
    };

    /**
     * decode token string
     * @param token
     * @returns parsed token as CustomJWT or undefined
     */
    getUserDataFromToken = (token: string): CustomJWT | undefined => {
        let decodedToken = undefined;
        if (token) decodedToken = jwt<CustomJWT>(token);

        return decodedToken;
    };

    /**
     * Introspect a token
     * @param token
     * @returns introspect result
     */
    introspect = async (
        token?: string,
        authHeader?: string
    ): Promise<CustomJWT | undefined> => {
        let result: CustomJWT | undefined;
        if (!token || !authHeader) return result;

        try {
            let payload = new URLSearchParams();
            payload.append('token', token);
            const { data } = await axios
                .create({
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Requested-With': 'XMLHttpRequest',
                        Authorization: authHeader,
                    },
                })
                .post(process.env.REACT_APP_INTROSPECT_URL!, payload);

            result = data;
        } catch (error) {
            // TODO: SOMETHING
        } finally {
            return result;
        }
    };
}

export default new AuthenticationService();

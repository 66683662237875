import { useEffect, useRef } from 'react';

/**
 * Lifecycle hook to see if a component is mounted
 * @returns tuple [isMounted: boolean]
 */
const useIsMounted = (): [boolean] => {
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return [isMounted.current];
};

export default useIsMounted;

import { useState } from 'react';

import {
    Notification,
    NotificationTheme,
} from '../../types/notifications/Notifications';
import NotificationHelper from '../../helpers/notifications/NotificationHelper';
import { ToastProps } from 'react-bootstrap';

const useNotifications = (): [
    Notification[],
    (
        text: string,
        theme: NotificationTheme,
        toastProps: ToastProps | undefined
    ) => void,
    () => Notification | undefined
] => {
    const DEFAULT_DELAY: number = 3000;

    const [notifications, setNotifications] = useState<Notification[]>([]);

    /**
     * notification closed via x or auto
     * @param notificationId
     */
    const onClose = (notificationId: string) => {
        let tempNotifications = [...notifications];

        // find the notification
        const idx = tempNotifications.findIndex(
            ({ id }: Notification) =>
                id.toUpperCase() === notificationId.toUpperCase()
        );

        // set show=false
        if (idx !== -1) {
            const tempNotification = tempNotifications[idx];
            // default to empty
            tempNotification.toastProps = tempNotification.toastProps || {};
            tempNotification.toastProps.show = false;
        }

        // update state
        setNotifications(tempNotifications);
    };

    /**
     * Adds notification to front of array
     * @param text
     * @param theme
     */
    const addNotification = (
        text: string,
        theme: NotificationTheme,
        toastProps: ToastProps | undefined = undefined
    ) => {
        let notificationToAdd = NotificationHelper.getNotification(text, theme);
        _processToastProps(toastProps, notificationToAdd);

        let tempNotifications = [notificationToAdd, ...notifications];
        setNotifications(tempNotifications);
    };

    /**
     * Remove the 'next' notification i.e. remove the notification that was last added
     * @returns notification removed or undefined
     */
    const removeNextNotification = (): Notification | undefined => {
        let tempNotifications = [...notifications];
        const removed = tempNotifications.pop();

        setNotifications(tempNotifications);
        return removed;
    };

    /**
     * helper to process the ToastProps config
     * @param toastProps
     * @param notification
     */
    const _processToastProps = (
        toastProps: ToastProps | undefined,
        notification: Notification
    ) => {
        if (toastProps === undefined) return;

        if (toastProps.autohide === true) {
            toastProps.delay = toastProps.delay || DEFAULT_DELAY;
            toastProps.onClose = () => {
                onClose(notification.id);
            };
        }

        notification.toastProps = toastProps;
    };

    return [notifications, addNotification, removeNextNotification];
};

export default useNotifications;

import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

type ProtectedRouteProps = {
    path: string;
    component: React.ComponentType<{}>;
    authorized: boolean;
    exact: boolean;
};

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
    component: Component,
    authorized,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authorized) return <Component {...rest} {...props} />;
                else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

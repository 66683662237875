import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import AuthenticationService from '../services/AuthenticationService';
import { COOKIE_KEYS } from '../constants/SQConstants';

const axiosInstance = (): AxiosInstance => {
    let cookies = new Cookies();

    const sqWebRequest: AxiosInstance = axios.create({
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest', //Header needed for server to respond with 401 instead of 302 when not logged in
        },
    });

    sqWebRequest.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            if (config.url !== `${process.env.REACT_APP_TOKEN_URL}`) {
                config.headers.Authorization = `Bearer ${cookies.get(
                    COOKIE_KEYS.API_ACCESS_TOKEN
                )}`;
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    sqWebRequest.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;

            if (
                error.response &&
                (error.response.status === 403 ||
                    error.response.status === 401) &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true;
                const access_token =
                    await AuthenticationService.refreshAccessToken();
                axios.defaults.headers.common['Authorization'] =
                    'Bearer ' + access_token;
                return sqWebRequest(originalRequest);
            }

            return Promise.reject(error);
        }
    );

    return sqWebRequest;
};

export default axiosInstance;

import Colors from '../constants/Colors';
import { Tab } from '../types/Tab';

export const getFilters = (
    needsAttentionCount: number,
    newCount: number
): Tab[] => [
    { id: 'all', label: 'ALL' },
    {
        id: 'needsAttention',
        label: 'NEEDS ATTENTION',
        badgeText: needsAttentionCount?.toString() || '',
        badgeBgColor: Colors.bgLightRed,
        badgeTextColor: Colors.textRed,
    },
    {
        id: 'new',
        label: 'NEW',
        badgeText: newCount?.toString() || '',
        badgeBgColor: Colors.bgLightBlue,
        badgeTextColor: Colors.textBlue,
    },
    { id: 'viewed', label: 'VIEWED' },
    { id: 'completed', label: 'COMPLETED' },
    { id: 'archived', label: 'ARCHIVED' },
];

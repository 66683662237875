/**
 * represents a single filter tab
 */
import { FunctionComponent, useEffect, useState } from 'react';
import { Tab } from '../../../types/Tab';

type FilterTabProps = {
    tab: Tab;
    selected: boolean;
    onClick(id: string): void;
};

export const FilterTab: FunctionComponent<FilterTabProps> = ({
    tab: { id, label, badgeBgColor, badgeTextColor, badgeText },
    selected,
    onClick,
}) => {
    const selectedClass = selected ? 'selected' : 'not_selected';
    const [showBadge, setShowBadge] = useState<boolean>(true);

    useEffect(() => {
        if (badgeText) {
            let parsedInt: number = parseInt(badgeText);
            if (!isNaN(parsedInt)) {
                setShowBadge(parsedInt > 0);
            }
        }
    }, [badgeText]);

    return (
        <div
            data-testid="filter_tab"
            className={'filtertab ' + selectedClass}
            onClick={() => {
                onClick(id);
            }}
        >
            <span data-testid="filter_tab_span" className="filtertab_label">
                {label}
                {badgeBgColor && badgeText && badgeTextColor && showBadge && (
                    <span
                        data-testid="filter_tab_span_badge"
                        className="filtertab_badge"
                        style={{
                            backgroundColor: badgeBgColor,
                            color: badgeTextColor,
                        }}
                    >
                        {badgeText}
                    </span>
                )}
            </span>
            <div className="hr"></div>
        </div>
    );
};

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

//Query to get all incidents belonging to specific organisation
const getAllIncidentsQuery = (
    orgId: string,
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortAscending: boolean,
    personId: string
): DocumentNode => {
    const sortDirection = sortAscending ? 1 : -1;
    return gql`
    {
        getCountOfIncidents(input: {
            partition: "${orgId}_admin",
            pageSize: ${pageSize}, 
            status: "All",
            personId: "${personId}"
        }) 
        {
            newIncidentsCount
            needsAttentionCount
            pageCount
        }
        getPaginatedIncidents (input: { 
            partition: "${orgId}_admin",
            limit: ${pageSize}, 
            offset: ${pageIndex * pageSize}, 
            status: "All",
            sortField: "${sortField}",
            sortDirection: ${sortDirection},
            personId: "${personId}"
        }) 
        {
            _id
            SQLServerId
            description
            organisation {
                id
                name
            }
            status {
                name
            }
            type {
                SQLServerId
                name
            }
            project {
                SQLServerId
                name
            }
            occurenceDateTime
            medias {
                location
                locationThumbnail
            }
            name
            personInvolved {
                firstName
			    lastName
                SQLServerId
            }
            customerIncidentId
            location {
                latitude
                longitude
                description
            }
            reportedBy {
                SQLServerId
                firstName
			    lastName
            }
            classifications
            createDateTimeStamp
        }   
    }`;
};

//Query to get filtered incidents belonging to specific organisation
const getIncidentsByStatusQuery = (
    orgId: string,
    status: string,
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortAscending: boolean,
    personId: string
): DocumentNode => {
    const sortDirection = sortAscending ? 1 : -1;
    return gql`
    {
        getCountOfIncidents(input: { 
            partition: "${orgId}_admin",
            pageSize: ${pageSize}, 
            status: "${status}",
            personId: "${personId}"
        }) {
            newIncidentsCount
            needsAttentionCount
            pageCount
        }
        getPaginatedIncidents (input: { 
            partition: "${orgId}_admin",  
            status: "${status}", 
            limit: ${pageSize}, 
            offset: ${pageIndex * pageSize},
            sortField: "${sortField}",
            sortDirection: ${sortDirection},
            personId: "${personId}"
        }) {
            _id
            SQLServerId
            description
            organisation {
                id
                name
            }
            status {
                name
            }
            type {
                SQLServerId
                name
            }
            project {
                SQLServerId
                name
            }
            occurenceDateTime
            medias {
                location
                locationThumbnail
            }
            name
            personInvolved {
                SQLServerId
                firstName
                lastName
            }
            customerIncidentId
            location{
                latitude
                longitude
                description
            }
            reportedBy {
                SQLServerId
                firstName
                lastName
            }
            classifications
            createDateTimeStamp
        }
    }`;
};

//Mutation to update incident status
const updateIncidentStatus: DocumentNode = gql`
    mutation updateIncidentStatus($input: IncidentStatusObject) {
        updateIncidentStatus(input: $input)
    }
`;

export {
    getAllIncidentsQuery,
    getIncidentsByStatusQuery,
    updateIncidentStatus,
};

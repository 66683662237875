import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * wrapper hook for useLocation to parse query string
 * @returns tuple [
 *  URLSearchParams
 *  addQueryParam
 * ]
 */
const useQueryParams = (): [URLSearchParams, (queryString: string) => void] => {
    const { search, pathname } = useLocation();
    const { push } = useHistory();

    const addQueryParam = (queryString: string): void => {
        push({
            pathname,
            search: queryString,
        });
    };

    return [
        React.useMemo(() => new URLSearchParams(search), [search]),
        addQueryParam,
    ];
};

export default useQueryParams;

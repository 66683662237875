import { v4 as uuidv4 } from 'uuid';

import {
    Notification,
    NotificationTheme,
} from '../../types/notifications/Notifications';

/**
 * helper for working with notifications
 */
class NotificationHelper {
    /**
     * get a Notification object by text and theme

     * @param text
     * @param theme
     * @returns Notification
     */
    static getNotification(
        text: string,
        theme: NotificationTheme = NotificationTheme.default
    ): Notification {
        return {
            id: uuidv4(),
            notificationText: text,
            theme,
            toastProps: {
                show: true,
            },
        };
    }
}

export default NotificationHelper;
